<template>
  <div class="team-access-block">
    <team-multi-autocomplete
      v-on:selected="update_city_select"
      v-on:deleted="remove_city_from_selected"
      @filter="search_input"
      class="team-access-block__element"
      id="city_select"
      ref="city_id"
      :title="$t('team.city')"
      :disabled="this.cities.length === 0 && this.selected_cities.length === 0"
      :options="cities"
      :selectedOptions="selected_cities"
      :clear="clear"
      v-on:reset="reset_clear"
      :placeholder="render_city_placeholder()"
    >
    </team-multi-autocomplete>
    <team-multi-autocomplete
      v-on:selected="update_pos_select"
      v-on:deleted="remove_pos_from_selected"
      @filter="search_pos_input"
      class="team-access-block__element"
      id="pos_select"
      ref="pos_id"
      :title="$t('team.pos')"
      :disabled="check_pos_disabled()"
      :options="poses"
      :selectedOptions="selected_poses"
      :clear="clear_pos"
      v-on:reset="reset_clear_pos"
      :placeholder="render_pos_placeholder()"
    >
    </team-multi-autocomplete>
  </div>
</template>

<script>
import teamMultiAutocomplete from "@/components/components/team/teamMultiAutocomplete";

export default {
  name: "teamAccessBlockEdit",
  props: {
    value: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: { teamMultiAutocomplete },
  data() {
    return {
      clear: false,
      clear_pos: false,
      search_input: "",
      search_pos_input: "",
      poses: [],
      cities: [],
      selected_cities: [],
      selected_poses: [],
    };
  },
  created() {
    this.get_lists();
  },
  computed: {
    team_id() {
      return this.value.id;
    },
    edit_team_access: {
      get() {
        return this.$store.getters.EDIT_TEAM_ACCESS;
      },
      set(value) {
        this.$store.dispatch("update_edit_team_access", value);
      },
    },
  },
  methods: {
    check_pos_disabled() {
      return this.selected_cities.length === 0;
    },
    get_lists() {
      this.$store
        .dispatch("load_team_pos_available", this.team_id)
        .then((response) => {
          const { data } = response.data;
          this.cities = data.available
            ? data.available.map((city) => {
                return {
                  value: city.id,
                  uuid: city.id,
                  action: city.action,
                  area: city.area,
                  region: city.region,
                  pos: {
                    available: city.pos.available ?? [],
                    selected: city.pos.selected ?? [],
                  },
                  checked: city.checked ?? false,
                  name: `${city.name}`,
                };
              })
            : [];
          this.selected_cities = data.selected
            ? data.selected.map((city) => {
                return {
                  value: city.id,
                  uuid: city.id,
                  action: city.action,
                  area: city.area,
                  region: city.region,
                  pos: {
                    available: city.pos.available ?? [],
                    selected: city.pos.selected ?? [],
                  },
                  checked: true,
                  name: `${city.name}`,
                };
              })
            : [];
          this.generate_pos_list();
          this.update_access_lists();
        });
    },
    render_city_placeholder() {
      let cities_count = this.selected_cities.length;
      if (cities_count === 0) {
        return this.$i18n.t("team.all_cities");
      }
      let last_digit = (cities_count + "").split("").pop();
      let count_end = "";
      switch (last_digit) {
        case "1":
          count_end = this.$i18n.t("team.endings_city.single");
          break;
        case "2":
        case "3":
        case "4":
          count_end = this.$i18n.t("team.endings_city.multiple_a");
          break;
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
          count_end = this.$i18n.t("team.endings_city.multiple_b");
          break;
      }

      if (cities_count >= 11 && cities_count < 15) {
        count_end = this.$i18n.t("team.endings_city.multiple_b");
      }
      return `${cities_count} ${count_end}`;
    },
    render_pos_placeholder() {
      let poses_count = this.selected_poses.length;
      if (poses_count === 0) {
        return this.$i18n.t("team.all_poses");
      }
      let last_digit = (poses_count + "").split("").pop();
      let count_end = "";
      switch (last_digit) {
        case "1":
          count_end = this.$i18n.t("team.endings_pos.single");
          break;
        case "2":
        case "3":
        case "4":
          count_end = this.$i18n.t("team.endings_pos.multiple_a");
          break;
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
          count_end = this.$i18n.t("team.endings_pos.multiple_b");
          break;
      }

      if (poses_count >= 11 && poses_count < 15) {
        count_end = this.$i18n.t("team.endings_pos.multiple_b");
      }
      return `${poses_count} ${count_end}`;
    },
    reset_clear() {
      this.clear = false;
    },
    reset_clear_pos() {
      this.clear = false;
    },
    generate_pos_list(generate_selected = true) {
      if (this.selected_cities.length === 0) {
        this.selected_poses = [];
        return [];
      }
      let available_poses = this.selected_cities
        ?.map((city) => {
          return city.pos.available;
        })
        ?.flat();
      let selected_poses = this.selected_cities
        ?.map((city) => {
          return city.pos.selected;
        })
        ?.flat()
        .filter((x) => x);
      if (generate_selected) {
        this.selected_poses = selected_poses?.map((pos) => {
          if (pos) {
            return {
              city_id: this.selected_cities.find((x) => {
                return (
                  x.pos.selected?.includes(pos) ||
                  x.pos?.available?.includes(pos)
                );
              })?.value,
              value: pos?.id ?? pos?.value,
              checked: true,
              name: pos?.name,
            };
          }
        });
      }

      this.poses = available_poses
        ?.map((pos) => {
          return {
            city_id: this.selected_cities.find((x) => {
              return (
                x.pos?.selected?.includes(pos) ||
                x.pos?.available?.includes(pos)
              );
            })?.value,
            value: pos?.id ?? pos?.value,
            checked: false,
            name: pos?.name,
          };
        })
        .filter((x) => x);
    },
    remove_pos_from_list(item) {
      this.selected_poses = this.selected_poses.filter(
        (x) => x.city_id !== item.value
      );
    },
    update_city_select(item) {
      item.checked = true;
      this.selected_cities.push(item);
      this.cities = this.cities.filter((x) => x.value !== item.value);
      this.generate_pos_list();
      this.update_access_lists();
    },
    remove_city_from_selected(item) {
      item.checked = false;
      this.selected_cities = this.selected_cities.filter(
        (x) => x.value !== item.value
      );
      this.selected_poses = this.selected_poses.filter(
        (x) => x.city_id !== item.value
      );
      if (item.pos.selected) {
        item.pos.available = [...item.pos?.available, ...item.pos?.selected];
      }
      item.pos.selected = [];
      this.cities.push(item);
      this.generate_pos_list();
      this.update_access_lists();
    },
    update_pos_select(item) {
      item.checked = true;
      this.poses = this.poses.filter((x) => x.value !== item.value);
      this.selected_poses.push(item);
      this.change_current_city_data(item);
      this.generate_pos_list();
      this.update_access_lists();
    },
    remove_pos_from_selected(item) {
      (item.checked = false),
        (this.selected_poses = this.selected_poses.filter(
          (x) => x.value !== item.value
        ));
      this.poses.push(item);
      this.change_current_city_data(item);
      this.update_access_lists();
    },
    change_current_city_data(item) {
      let currentCityIndex = this.selected_cities.findIndex(
        (x) => x.value === item.city_id
      );
      this.selected_cities[currentCityIndex].pos.selected =
        this.selected_poses.filter(
          (x) => x.city_id === this.selected_cities[currentCityIndex].value
        );
      this.selected_cities[currentCityIndex].pos.available = this.poses.filter(
        (x) => x.city_id === this.selected_cities[currentCityIndex].value
      );
    },
    update_access_lists() {
      let defaultStructure = this.selected_cities.map((city) => {
        return {
          city: {
            id: city.value,
          },
          pos: this.selected_poses
            .filter((x) => x.city_id === city.value)
            .map((pos) => {
              return { id: pos.value };
            }),
        };
      });
      this.$store.dispatch("update_edit_team_access", defaultStructure);
    },
  },
};
</script>

<style scoped>
.team-access-block {
  display: flex;
  gap: 30px;
  margin-top: 32px;
  justify-content: flex-start;
  align-items: flex-end;
}

.team-access-block__element {
  width: 337px;
}
</style>
