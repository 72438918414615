<template>
  <div class="dropdown" v-if="options" ref="dropdown">
    <span class="dropdown--title" :class="{ invalid_field: invalid }">{{
      title
    }}</span>
    <!-- Dropdown Input -->
    <input
      autocomplete="off"
      class="dropdown-input"
      :name="select_value"
      @click="showOptions()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />

    <!-- Dropdown Menu -->
    <template>
      <div class="dropdown-content" v-show="optionsShown">
        <div
          :title="option.name"
          class="dropdown-item"
          @mousedown="selectOption(option)"
          v-for="option in selectedOptions"
          :key="option.value"
        >
          <span>
            <div
              :class="
                option.checked
                  ? 'select_selected-item'
                  : 'select_unselected-item'
              "
            ></div
          ></span>
          <div>
            <div class="dropdown-item__title">{{ option.name }}</div>
          </div>
        </div>
        <div
          class="dropdown-divider"
          v-if="selectedOptions.length > 0 && filteredOptions.length > 0"
        ></div>
        <div
          :title="option.name"
          class="dropdown-item"
          @mousedown="selectOption(option)"
          v-for="option in filteredOptions"
          :key="option.value"
        >
          <span>
            <div
              :class="
                option.checked
                  ? 'select_selected-item'
                  : 'select_unselected-item'
              "
            ></div
          ></span>
          <div>
            <div class="dropdown-item__title">{{ option.name }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "teamMultiAutocomplete",
  template: "Dropdown",
  props: {
    value: {
      type: [Number, String, Object],
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
      note: "Options of dropdown. An array of options with id and name",
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 0,
      note: "Max items showing",
    },
    clear: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      selected: "",
      optionsShown: false,
      searchFilter: "",
      multiDropdown: "",
    };
  },
  created() {
    document.addEventListener("click", this.dropdown);
  },
  destroyed() {
    document.removeEventListener("click", this.dropdown);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      // const regOption = new RegExp(this.searchFilter.text, "ig");
      for (const option of this.options) {
        if (
          this.searchFilter.length < 1 ||
          String(option.name)
            .toLocaleLowerCase()
            .includes(String(this.searchFilter).toLocaleLowerCase()) ||
          String(option.checked)
            .toLocaleLowerCase()
            .includes(String(this.searchFilter).toLocaleLowerCase())
        ) {
          if (this.maxItem) {
            if (filtered.length < this.maxItem) filtered.push(option);
          } else {
            filtered.push(option);
          }
        }
      }
      return _.orderBy(filtered, ["checked"], ["desc"]);
    },
    select_value() {
      if (this.value) {
        let selected = this.options.find((item) => item.value == this.value);
        return this.selectedOption(selected);
      }
      return this.clear_field();
    },
  },
  methods: {
    render_additional_city_info(item) {
      let info = [];
      if (item.region) {
        info.push(item.region);
      }

      return `${info.join(", ")}`;
    },
    dropdown(e) {
      let el = this.$refs.dropdown;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.optionsShown = false;
      }
    },
    selectedOption(option) {
      this.selected = option?.value;
      option.checked = !option.checked;
      this.searchFilter = option?.name;
    },
    selectOption(option, favorite = false) {
      this.selected = option.value;
      if (option.checked) {
        this.$emit("deleted", option);
        option.checked = false;
      } else if (option.action === "delete") {
        this.$emit("deleted", option);
        option.checked = true;
      } else {
        let option_data = {
          ...option,
        };
        if (favorite) {
          option_data.favorite = true;
        }
        this.$emit("selected", option_data);
        option.checked = true;
      }
    },
    clear_field() {
      this.searchFilter = "";
      this.selected = "";
      this.$emit("reset");
    },
    validate() {
      if (this.disabled) return { valid: true, error: "" };
      let fn = true;
      for (let key in this.rules) {
        let res = this.rules[key](this.selected);
        if (typeof res === "string" || !res) {
          fn = res;
          this.invalid = true;
          break;
        }
      }
      if (typeof fn === "string") return { valid: false, error: fn };
      else if (fn) {
        this.invalid = false;
        return { valid: true, error: "" };
      } else return { valid: false, error: "" };
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = !this.optionsShown;
      }
    },
    exit() {
      if (this.selected == "" || this.selected.value) {
        this.selected = "";
        this.searchFilter = "";
      }
      this.$emit("selected", this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = "";
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    clear() {
      if (this.clear) {
        this.searchFilter = "";
        this.selected = "";
        this.$emit("reset");
      }
    },
  },
};
</script>

<style scoped>
.dropdown--title {
  color: #1f2120;
}
.dropdown-input {
  font-family: Effra, sans-serif;
  padding: 0 0 0 14px;
  position: relative;
  width: calc(100% - 15px);
  height: 44px;
  min-height: 44px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1f2120;
  border: 1px #c4c4c4 solid;
  border-radius: 8px;

  background: url("../../../../src/assets/select_arrow.svg") #f9f9f9 no-repeat
    right 18px center;
}
.noclick {
  pointer-events: none;
}

.dropdown-input:hover:enabled {
  cursor: pointer;
  background-color: #e5e5e5;
}

.dropdown-input:disabled {
  background-color: #d0d0d0;
}
.dropdown-input::placeholder {
  color: #dddddd;
  opacity: 1;
}
.dropdown-input:disabled::placeholder {
  color: #6d6d6d;
  opacity: 1;
}
.dropdown {
  text-align: left;
  margin: 0;
  padding: 0;
  color: #575858;
  position: relative;
  display: block;
}
.dropdown:hover .dropdowncontent {
  display: block;
}

.dropdown-content {
  position: absolute;
  display: block;
  margin-top: 8px;
  width: 338px;
  z-index: 100;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(39, 87, 255, 0.1), 0 2px 8px rgba(39, 87, 255, 0.5);
  border-radius: 8px;
  overflow-y: overlay;
  scrollbar-color: #c4c4c4cc transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  max-height: 150px;
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #c4c4c4cc;
  border-radius: 40px;
  width: 8px;
}
.dropdown-content::-webkit-scrollbar-track {
  background: transparent;
}

.dropdown-item {
  width: 512px;
  padding-left: 16px;
  height: 50px;
  line-height: 20px;
  gap: 6px;

  display: flex;
  align-items: center;
  background-color: white;
}

.dropdown-item__text {
  font-size: 13px;
  color: #b3b3b3;
}

.dropdown-item__title {
  font-size: 17px;
}

.dropdown-divider {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #c4c4c4;
}

.dropdown-item:hover {
  border-radius: 0 0 8px 8px;
  background-color: #e5e5e5;
  outline: solid 1px #e5e5e5;
}
.select_selected-item {
  width: 20px;
  height: 20px;

  mask-image: url("../../../assets/check_select.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: #214fff;
}
.select_unselected-item {
  width: 20px;
  height: 20px;

  mask-image: url("../../../assets/check_unchecked_select.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: #c2c2c4;
}
</style>
