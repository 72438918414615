<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item"
        v-for="item in header_items"
        :key="item.name"
        :class="item.name"
        :style="item.name === active_name ? active_style : ''"
        @click="
          $emit('change_sorted', item.name);
          set_active_style(item.name);
        "
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
      <div
        class="table__cell table__header-item team-table__img-header"
        v-if="
          $auth.check({ permissions: 'add_users' }) ||
          $auth.check({ name: 'admin' })
        "
      ></div>
    </div>
    <div class="table__data">
      <template v-for="item in items">
        <div class="table__row" :key="item.employee_id">
          <div v-if="show_change_item !== item" class="table-2__cells">
            <div
              class="table__cell"
              v-for="cell in Object.keys(item)"
              :key="cell"
              v-show="show_cell(cell)"
              @click="set_active_style(cell)"
              :class="cell"
              :style="cell === active_name ? active_style : ''"
            >
              <div class="table__cell-text">{{ item[cell] }}</div>
            </div>
            <div
              class="table__cell table-2__img-cell team-table__img-cell"
              v-if="
                $auth.check({ permissions: 'add_users' }) ||
                $auth.check({ name: 'admin' })
              "
            >
              <edit-btn @click="set_show_change_item(item)"></edit-btn>
              <delete-btn @click="call_confirm_dialog(item)"></delete-btn>
            </div>
          </div>
          <div class="table-2__change-items table-2__change-items_team" v-else>
            <team-change-block
              :value="item"
              @cancel="close_change_block"
              @delete="call_confirm_dialog(item)"
            ></team-change-block>
          </div>
        </div>
      </template>
    </div>
    <confirm
      :enable="show_confirm"
      src="confirm_delete.svg"
      :button_text="confirm_btn"
      :text="confirm_text"
      :error_text="error_text"
      text_width="217"
      @cancel="close_confirm_dialog"
      @confirm="confirm_delete"
    ></confirm>
  </div>
</template>

<script>
import Confirm from "@/components/recurringComponents/confirmDialog";
import teamChangeBlock from "@/components/components/team/teamChangeBlock";
import EditBtn from "@/components/recurringComponents/editBtn";
import DeleteBtn from "@/components/recurringComponents/deleteBtn";

export default {
  name: "teamTable",
  components: { DeleteBtn, EditBtn, Confirm, teamChangeBlock },
  props: {
    header_items: Array,
    items: Array,
  },
  data() {
    return {
      show_confirm: false,
      remove_employee: null,
      show_change_item: null,
      active_name: "",
      active_style: "",
      team_auth: false,
      error_text: "",
    };
  },
  watch: {
    items: {
      handler: function () {
        this.active_name = "";
      },
      deep: true,
    },
  },
  computed: {
    confirm_text() {
      if (this.team_auth) {
        return this.$t("team.auth_error");
      }
      return this.$t("team.confirm_text");
    },
    confirm_btn() {
      if (this.team_auth) {
        return this.$t("team.close_button");
      }
      return this.$t("team.confirm_button");
    },
  },
  methods: {
    show_cell(item) {
      return this.header_items.find((header) => header.name === item);
    },
    call_confirm_dialog(item) {
      this.remove_employee = item;
      this.show_confirm = true;
    },
    close_confirm_dialog() {
      this.remove_employee = null;
      this.show_confirm = false;
      this.error_text = null;
    },
    confirm_delete() {
      if (this.team_auth && this.remove_employee.auth) {
        this.show_confirm = false;
        this.team_auth = false;
        this.remove_employee = null;
        return;
      }
      if (this.remove_employee.auth) {
        this.team_auth = true;
        this.show_confirm = false;
        this.$nextTick(() => {
          this.show_confirm = true;
        });
        return;
      }
      this.$store
        .dispatch("delete_employee", { id: this.remove_employee.id })
        .then(() => {
          this.show_confirm = false;
        })
        .catch((error) => {
          this.error_text = error.response?.data?.errors?.find(
            (x) => x
          ).message;
        });
    },
    set_show_change_item(item) {
      this.show_change_item = item;
    },
    close_change_block() {
      this.show_change_item = null;
      this.$store.dispatch("load_team");
    },
    set_active_style(name) {
      let active_width = 0;
      let fn = false;

      let collection = document.getElementsByClassName(name);
      for (let item of collection) {
        if (
          item.scrollWidth > item.clientWidth &&
          item.scrollWidth > active_width
        ) {
          active_width = item.scrollWidth;
          fn = true;

          item.addEventListener("transitionend", this.set_overflow);
        }
      }

      if (fn) {
        this.active_name = name;
        this.active_style = `flex: 0 0 ${active_width}px;`;
      }
    },

    set_overflow() {
      this.active_style += "overflow: visible";
    },
  },
};
</script>

<style scoped></style>
