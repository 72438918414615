<template>
  <div class="team-access-block">
    <team-multi-autocomplete
      v-on:selected="update_city_select"
      v-on:deleted="remove_city_from_selected"
      @filter="search_input"
      class="team-access-block__element"
      id="city_select"
      ref="city_id"
      :title="$t('team.city')"
      :invalid="city_invalid"
      :disabled="cities.length === 0 && selected_cities.length === 0"
      :options="cities"
      :selectedOptions="selected_cities"
      :clear="clear"
      v-on:reset="reset_clear"
      :placeholder="render_city_placeholder()"
    >
    </team-multi-autocomplete>
    <team-multi-autocomplete
      v-on:selected="update_pos_select"
      v-on:deleted="remove_pos_from_selected"
      @filter="search_pos_input"
      class="team-access-block__element"
      id="pos_select"
      ref="pos_id"
      :title="$t('team.pos')"
      :disabled="check_pos_disabled()"
      :options="poses"
      :selectedOptions="selected_poses"
      :clear="clear_pos"
      v-on:reset="reset_clear_pos"
      :placeholder="render_pos_placeholder()"
    >
    </team-multi-autocomplete>
  </div>
</template>

<script>
import teamMultiAutocomplete from "@/components/components/team/teamMultiAutocomplete";

export default {
  name: "customersAccessBlock",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: { teamMultiAutocomplete },
  data() {
    return {
      clear: false,
      clear_pos: false,
      search_input: "",
      search_pos_input: "",
      poses: [],
      cities: [],
      selected_cities: [],
      selected_poses: [],
    };
  },
  created() {
    this.get_lists();
  },
  computed: {
    new_team_access: {
      get() {
        return this.$store.getters.NEW_TEAM_ACCESS;
      },
      set(value) {
        this.$store.dispatch("update_new_team_access", value);
      },
    },
    city_invalid() {
      if (this.cities.length === 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
    clear_selected() {
      this.selected_cities = [];
      this.selected_poses = [];
    },
    get_lists() {
      this.$store.dispatch("load_cities", true).then((response) => {
        const { data } = response.data;
        this.cities = data
          ? data.map((city) => {
              return {
                value: city.id,
                uuid: city.id,
                action: city.action,
                area: city.area,
                region: city.region,
                pos: city.pos,
                checked: city.checked ?? false,
                name: `${city.name}`,
              };
            })
          : [];
      });
    },
    check_pos_disabled() {
      return (
        this.selected_cities.length === 0 ||
        (this.poses.length === 0 && this.selected_poses.length === 0)
      );
    },
    render_city_placeholder() {
      let cities_count = this.selected_cities.length;
      if (cities_count === 0) {
        return this.$i18n.t("team.all_cities");
      }
      let last_digit = (cities_count + "").split("").pop();
      let count_end = "";
      switch (last_digit) {
        case "1":
          count_end = this.$i18n.t("team.endings_city.single");
          break;
        case "2":
        case "3":
        case "4":
          count_end = this.$i18n.t("team.endings_city.multiple_a");
          break;
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
          count_end = this.$i18n.t("team.endings_city.multiple_b");
          break;
      }

      if (cities_count >= 11 && cities_count < 15) {
        count_end = this.$i18n.t("team.endings_city.multiple_b");
      }
      return `${cities_count} ${count_end}`;
    },
    render_pos_placeholder() {
      let poses_count = this.selected_poses.length;
      if (poses_count === 0) {
        return this.$i18n.t("team.all_poses");
      }
      let last_digit = (poses_count + "").split("").pop();
      let count_end = "";
      switch (last_digit) {
        case "1":
          count_end = this.$i18n.t("team.endings_pos.single");
          break;
        case "2":
        case "3":
        case "4":
          count_end = this.$i18n.t("team.endings_pos.multiple_a");
          break;
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
          count_end = this.$i18n.t("team.endings_pos.multiple_b");
          break;
      }

      if (poses_count >= 11 && poses_count < 15) {
        count_end = this.$i18n.t("team.endings_pos.multiple_b");
      }
      return `${poses_count} ${count_end}`;
    },
    reset_clear() {
      this.clear = false;
    },
    reset_clear_pos() {
      this.clear = false;
    },
    generate_pos_list() {
      let poses = this.selected_cities
        .map((city) => {
          return city.pos;
        })
        ?.flat();
      if (this.selected_cities.length === 0 && poses.length === 0) {
        this.selected_poses = [];
        return [];
      }
      this.poses = poses
        .map((pos) => {
          return {
            city_id: this.selected_cities.find((x) => x.pos?.includes(pos))
              ?.value,
            value: pos.id,
            checked: pos.checked ?? false,
            name: pos.name,
            address: pos.address,
          };
        })
        .filter(
          (pos) => !this.selected_poses.find((item) => item.name === pos.name)
        );
    },
    update_city_select(item) {
      item.checked = true;
      this.selected_cities.push(item);
      this.cities = this.cities.filter((x) => x.value !== item.value);
      this.generate_pos_list();
      this.update_new_team_access_lists();
    },
    remove_city_from_selected(item) {
      item.checked = false;
      this.selected_cities = this.selected_cities.filter(
        (x) => x.value !== item.value
      );
      this.selected_poses = this.selected_poses.filter(
        (x) => x.city_id !== item.value
      );
      this.cities.push(item);
      this.generate_pos_list();
      this.update_new_team_access_lists();
    },
    update_pos_select(item) {
      item.checked = true;
      this.poses = this.poses.filter((x) => x.value !== item.value);
      this.selected_poses.push(item);
      this.update_new_team_access_lists();
    },
    remove_pos_from_selected(item) {
      (item.checked = false),
        (this.selected_poses = this.selected_poses.filter(
          (x) => x.value !== item.value
        ));
      this.poses.push(item);
      this.update_new_team_access_lists();
    },
    update_new_team_access_lists() {
      let defaultStructure = this.selected_cities.map((city) => {
        return {
          city: {
            id: city.value,
          },
          pos: this.selected_poses
            .filter((x) => x.city_id === city.value)
            .map((pos) => {
              return { id: pos.value };
            }),
        };
      });
      this.new_team_access.available_pos = defaultStructure;
      this.$store.dispatch("update_new_team_access", defaultStructure);
    },
  },
};
</script>

<style scoped>
.team-access-block {
  display: flex;
  gap: 20px;
  margin-top: 32px;
  justify-content: flex-start;
  align-items: flex-end;
}

.team-access-block__element {
  width: 336px;
}
</style>
