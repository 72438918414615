<template>
  <div class="text-fields-block__row">
    <div class="text-fields-block__element text-fields-block__element_team">
      <span :class="{ invalid_field: this.invalid.first_name }">{{
        $t("team.first_name")
      }}</span>
      <custom-text-field
        ref="first_name"
        :rules="[rules.require, rules.max_length_50, rules.min_one_letter]"
        class="text-fields-block__field"
        v-model="new_user.first_name"
        @change="refresh"
        :placeholder="$t('team.text_field_hint')"
      ></custom-text-field>
    </div>
    <div class="text-fields-block__element text-fields-block__element_team">
      <span :class="{ invalid_field: this.invalid.last_name }">{{
        $t("team.last_name")
      }}</span>
      <custom-text-field
        ref="last_name"
        :rules="[rules.require, rules.max_length_50, rules.min_one_letter]"
        class="text-fields-block__field"
        v-model="new_user.last_name"
        @change="refresh"
        :placeholder="$t('team.text_field_hint')"
      ></custom-text-field>
    </div>
    <div class="text-fields-block__element text-fields-block__element_team">
      <span :class="{ invalid_field: this.invalid.employee_id }">{{
        $t("team.uid")
      }}</span>
      <custom-text-field
        ref="employee_id"
        class="text-fields-block__field"
        :rules="id_rule"
        v-model="new_user.employee_id"
        :max="64"
        @change="refresh"
        :placeholder="$t('team.text_field_hint')"
        :disabled="for_table"
      >
        <simple-button
          v-if="!for_table"
          class="text-fields-block__generate-btn"
          :title="$t('team.generate')"
          @click="generate"
        ></simple-button>
      </custom-text-field>
    </div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import rules from "@/rules";

export default {
  name: "teamTextFields",
  props: {
    value: {
      first_name: String,
      last_name: String,
      employee_id: String,
    },
    for_table: Boolean,
  },
  components: { SimpleButton, CustomTextField },
  data() {
    return {
      // new_user: this.value,
      rules: rules,
      invalid: {
        first_name: false,
        last_name: false,
        employee_id: false,
      },
    };
  },
  computed: {
    new_user() {
      return this.value;
    },
    id_rule() {
      const idSymbolValidation = new RegExp(
        /^[\da-zA-Zа-яА-Я№#.,\-/_:;]+$/,
        "g"
      );
      return [
        (v) => !!v || "required",
        (v) => !this.user_exist(v) || "id_exist",
        (v) => v.toString().match(idSymbolValidation) || "wrong_format",
      ];
    },
  },
  methods: {
    user_exist(value) {
      let dubl = this.$store.getters.TEAM?.find((employee) => {
        return employee.employee_id == value;
      });
      return !(dubl === undefined);
    },
    refresh() {
      this.$emit("change", { value: this.new_user });
    },
    generate() {
      let new_uid = Math.round(Math.random() * 65535);
      if (!this.user_exist(new_uid)) {
        this.new_user.employee_id = new_uid;
      } else this.generate();
    },
    validate() {
      let error = false;
      let error_type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        this._data["invalid"][key] = !valid.valid;
        if (!valid.valid) {
          error_type = valid.error;
          error = true;
        }
      }

      // if (!this.for_table && this.user_exist()) {
      //   return { valid: false, error: "user_exists" };
      // } else
      return { valid: !error, error: error_type };
    },
  },
};
</script>

<style scoped></style>
