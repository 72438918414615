<template>
  <div class="change-block">
    <team-text-fields
      ref="fields"
      class=""
      v-model="change_item"
      :for_table="true"
    ></team-text-fields>
    <team-access-block-edit :value="change_item"></team-access-block-edit>

    <div v-if="info.loader" class="change-block__btn-block">
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div class="change-block__btn-block" v-else>
      <div>
        <custom-btn
          class="button button_theme_blue"
          :disabled="disabled_button"
          @click="edit_user"
          >{{ $t("team.change_block.save") }}</custom-btn
        >
        <simple-button
          :title="$t('team.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
      <custom-btn
        class="change-block__delete-button button button_theme_delete"
        @click="$emit('delete')"
      >
        <span>{{ $t("team.change_block.delete") }}</span>
        <div class="change-block__delete-icon"></div>
      </custom-btn>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import TeamTextFields from "@/components/components/team/teamTextFields";
import teamAccessBlockEdit from "@/components/components/team/teamAccessBlockEdit";
import Loader from "@/components/recurringComponents/loader";

export default {
  name: "teamChangeBlock",
  props: ["value"],
  components: {
    Loader,
    TeamTextFields,
    SimpleButton,
    CustomBtn,
    teamAccessBlockEdit,
  },
  data() {
    return {
      change_item: Object.assign({}, this.value),
      info: {
        error: false,
        type: "",
        loader: false,
      },
    };
  },
  computed: {
    disabled_button() {
      return Array.isArray(this.$store.getters.EDIT_TEAM_ACCESS)
        ? !this.$store.getters.EDIT_TEAM_ACCESS?.some((x) => x.pos?.length > 0)
        : false;
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "user_exists":
          return this.$i18n.t("team.error_exists");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_mac":
          return this.$i18n.t("team.loader.no_uid");
        case "no_auth":
          return this.$i18n.t("team.loader.no_auth");
        case "progress":
          return this.$i18n.t("team.loader.progress_edit");
        case "failed":
          return this.$i18n.t("team.loader.failed_edit");
        case "success":
          return this.$i18n.t("team.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    validate() {
      this.info.error = false;
      this.info.type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_user() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        const data = {
          first_name: this.change_item.first_name,
          last_name: this.change_item.last_name,
          available_pos: this.$store.getters.EDIT_TEAM_ACCESS,
        };
        this.$store
          .dispatch("update_employee", { data: data, id: this.change_item.id })
          .then(() => {
            this.info.type = "success";
            this.info.error = false;
          })
          .catch(() => {
            this.info.type = "failed";
            this.info.error = true;
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
