var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__header"},[_vm._l((_vm.header_items),function(item){return _c('div',{key:item.name,staticClass:"table__cell table__header-item",class:item.name,style:(item.name === _vm.active_name ? _vm.active_style : ''),on:{"click":function($event){_vm.$emit('change_sorted', item.name);
        _vm.set_active_style(item.name);}}},[_c('span',{staticClass:"table__header-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"table__header-arrows"},[_c('div',{staticClass:"table__header-arrow table__header-top-arrow",class:{
            'table__header-arrow_pressed': item.sorted === 1,
          }}),_c('div',{staticClass:"table__header-arrow table__header-bottom-arrow",class:{
            'table__header-arrow_pressed': item.sorted === -1,
          }})])])}),(
        _vm.$auth.check({ permissions: 'add_users' }) ||
        _vm.$auth.check({ name: 'admin' })
      )?_c('div',{staticClass:"table__cell table__header-item team-table__img-header"}):_vm._e()],2),_c('div',{staticClass:"table__data"},[_vm._l((_vm.items),function(item){return [_c('div',{key:item.employee_id,staticClass:"table__row"},[(_vm.show_change_item !== item)?_c('div',{staticClass:"table-2__cells"},[_vm._l((Object.keys(item)),function(cell){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_cell(cell)),expression:"show_cell(cell)"}],key:cell,staticClass:"table__cell",class:cell,style:(cell === _vm.active_name ? _vm.active_style : ''),on:{"click":function($event){return _vm.set_active_style(cell)}}},[_c('div',{staticClass:"table__cell-text"},[_vm._v(_vm._s(item[cell]))])])}),(
              _vm.$auth.check({ permissions: 'add_users' }) ||
              _vm.$auth.check({ name: 'admin' })
            )?_c('div',{staticClass:"table__cell table-2__img-cell team-table__img-cell"},[_c('edit-btn',{on:{"click":function($event){return _vm.set_show_change_item(item)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.call_confirm_dialog(item)}}})],1):_vm._e()],2):_c('div',{staticClass:"table-2__change-items table-2__change-items_team"},[_c('team-change-block',{attrs:{"value":item},on:{"cancel":_vm.close_change_block,"delete":function($event){return _vm.call_confirm_dialog(item)}}})],1)])]})],2),_c('confirm',{attrs:{"enable":_vm.show_confirm,"src":"confirm_delete.svg","button_text":_vm.confirm_btn,"text":_vm.confirm_text,"error_text":_vm.error_text,"text_width":"217"},on:{"cancel":_vm.close_confirm_dialog,"confirm":_vm.confirm_delete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }