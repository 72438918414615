<template>
  <div class="body">
    <div
      class="text-fields-block block-wide"
      v-if="
        $auth.check({ permissions: 'add_users' }) ||
        $auth.check({ name: 'admin' })
      "
    >
      <form @submit.prevent="add_team">
        <team-text-fields
          ref="add_team"
          v-model="new_user"
          :for_table="false"
        ></team-text-fields>
        <team-access-block></team-access-block>
        <div class="text-fields-block__btn-row">
          <loader v-if="info.loader"></loader>
          <custom-btn
            v-else
            class="button button_theme_blue"
            type="submit"
            :disabled="disabled_button"
            >{{ $t("team.add_user") }}</custom-btn
          >
          <span
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
            >{{ info_text }}</span
          >
        </div>
      </form>
    </div>
    <div class="team__table block-wide">
      <team-table
        :header_items="headers"
        :items="team"
        @change_sorted="change_sorted"
      ></team-table>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import TeamTextFields from "@/components/components/team/teamTextFields";
import TeamTable from "@/components/components/team/teamTable";
import teamAccessBlock from "../components/team/teamAccessBlock";
import Loader from "@/components/recurringComponents/loader";
export default {
  name: "team",
  components: { Loader, TeamTable, TeamTextFields, CustomBtn, teamAccessBlock },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
      new_user: {
        first_name: "",
        last_name: "",
        employee_id: null,
      },
      headers: [
        {
          name: "employee_id",
          title: this.$i18n.t("team.headers.employee_id"),
          sorted: 1,
        },
        {
          name: "first_name",
          title: this.$i18n.t("team.headers.first_name"),
          sorted: 0,
        },
        {
          name: "last_name",
          title: this.$i18n.t("team.headers.last_name"),
          sorted: 0,
        },
        {
          name: "authorized",
          title: this.$i18n.t("team.headers.authorized"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_team");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`team.headers.${item.name}`);
      }
    },
  },
  computed: {
    disabled_button() {
      return !this.$store.getters.NEW_TEAM_ACCESS?.some(
        (x) => x.pos.length > 0
      );
    },
    team() {
      return this.$store.getters.TEAM.map((employee) => {
        return {
          id: employee.id,
          employee_id: employee.employee_id,
          auth: employee.auth,
          first_name: employee.name,
          last_name: employee.surname,
          authorized: employee.auth
            ? this.$t("status.info.yes")
            : this.$t("status.info.no"),
        };
      });
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_letters":
          return this.$i18n.t("errors.no_letters");
        case "user_exists":
          return this.$i18n.t("team.loader.no_uid");
        case "no_auth":
          return this.$i18n.t("team.loader.no_auth");
        case "progress":
          return this.$i18n.t("team.loader.progress");
        case "failed":
          return this.$i18n.t("team.loader.failed");
        case "wrong_format":
          return this.$i18n.t("errors.numbers_letters_symbols");
        case "id_exist":
          return this.$i18n.t("team.loader.employee_id_exist");
        case "success":
          return "";
        default:
          return "";
      }
    },
  },
  methods: {
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.team.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    validate() {
      let valid = this.$refs.add_team.validate();
      this.info.error = !valid.valid;
      this.info.type = valid.error;
      return !this.info.error;
    },
    add_team() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.new_user.employee_id = this.new_user.employee_id.toString();
        this.new_user.available_pos = this.$store.getters.NEW_TEAM_ACCESS;
        this.$store
          .dispatch("add_employee", this.new_user)
          .then(() => {
            this.info.type = "success";
            this.new_user = {
              first_name: "",
              last_name: "",
              employee_id: null,
            };
          })
          .catch((err) => {
            this.info.error = true;
            if (
              err.response.data.errors.find((x) => x.message.includes("uid"))
            ) {
              this.info.type = "id_exist";
            } else {
              this.info.type = "failed";
            }
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
